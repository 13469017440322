<template>
    <div class="wrap">
        <div class="topBox">
            <!-- <div>销售金额： <CountUp :end="totalInfo.mustPay" :duration="0" ref="count" v-font="24" />元</div>
            <div style="margin-left: 20px">销售数量： <CountUp :end="totalInfo.totalOrder" :duration="0" ref="count" v-font="24" />元</div> -->
            <div class="queryBox">
                <DatePicker :value="dateRange" @on-change="dateRange = $event" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择开始结束时间" style="width: 200px" />
                <Input v-model="queryParams.deskCode" placeholder="请输入关键字" style="width: auto;margin-left: 10px" />
                <Button type="primary" style="margin-left: 10px" @click="handleQuery(index)">查询</Button>
            </div>
        </div>
        <Row>
            <Col span="12">
                <!-- <NumberInfo sub-title="总订单金额" :total="totalInfo.mustPay" /> -->
                <NumberInfo sub-title="总订单金额" :total="2083654" />
            </Col>
            <Col span="12">
                <!-- <NumberInfo sub-title="总订单数量" :total="totalInfo.totalOrder"/> -->
                <NumberInfo sub-title="总订单数量" :total="3438"/>
            </Col>
        </Row>
        <Table :columns="columns" :data="list" style="margin-top: 13px;">
            <template #orderCode="{ row }">
                <strong>{{ countOrderCode(row.orderCode) }}</strong>
            </template>
            <template #yuangong>
                <strong>-</strong>
            </template>
            <template #kefu>
                <strong>—</strong>
            </template>
            <template #status>
                <strong>已完成</strong>
            </template>
            <template #action>
                <Button type="primary" size="small" ghost>订单详情</Button>
            </template>
        </Table>
        <!-- <Page :total="total" @on-change="onChange" @on-page-size-change="onSizeChange" show-sizer show-total style="margin: 10px auto 0;display: flex; justify-content: flex-end;" /> -->
        <Page :total="3438" @on-change="onChange" @on-page-size-change="onSizeChange" show-sizer show-total style="margin: 10px auto 0;display: flex; justify-content: flex-end;" />
    </div>
</template>

<script>
export default {
  name: 'Demo',
  data() {
    return {
        queryParams: {
        pageNum: 1,
        pageSize: 10,
        deskCode: undefined,
        orderCode: undefined,
        status: undefined
      },
      // 表单参数
      form: {},
      totalInfo: {
          mustPay: 0,
          totalOrder: 0
      },
      dateRange: [],
      columns: [
        //   {
        //       title: '序号',
        //     type: 'index',
        //     width: 70,
        //     align: 'center'
        // },
        {
            title: '订单号',
            slot: 'orderCode',
            align: 'center'
        },
        {
            title: '桌号',
            key: 'deskCode',
            align: 'center'
        },
        {
            title: '会员号',
            slot: 'kefu',
            align: 'center'
        },
        {
            title: '金额',
            key: 'totalPrice',
            align: 'center'
        },
        {
            title: '订单时间',
            key: 'createTime',
            align: 'center'
        },
        {
            title: '状态',
            slot: 'status',
            align: 'center'
        },
        {
            title: '服务员',
            slot: 'yuangong',
            align: 'center'
        },
        {
            title: '设置',
            slot: 'action',
            width: 100,
            align: 'center'
        }
    ],
    total: 0,
    list: []
    }
  },
  mounted() {
    // const e = {
    //     data: {msgType: 'listInfo', data: {}}
    // }
    this.queryParams.pageNum = 14
		window.addEventListener('message', e => {
			if (e.data.msgType == 'totalInfo') {
        this.totalInfo = e.data.data
      } else if (e.data.msgType == 'listInfo') {
        if (this.queryParams.pageNum == 1) {
          const obj = {
    "code": 200,
    "msg": "成功!",
    "data": [
        {
            "createTime": "2024-05-26 11:27:35",
            "modifyTime": null,
            "del": null,
            "orderId": 350694,
            "orderCode": "2024-0526-1127-35-892",
            "deskCode": "12",
            "cashier": {
                "createTime": "2024-05-26 17:27:35",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-05-26 11:27:35",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-05-26 17:27:35",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 74.1,
            "totalPrice": 114.0,
            "totalProfit": 39.9,
            "actualPay": 114.0,
            "mustPay": 114.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-05-26 11:30:28",
            "modifyTime": null,
            "del": null,
            "orderId": 350693,
            "orderCode": "2024-0526-1143-28-119",
            "deskCode": "3",
            "cashier": {
                "createTime": "2024-05-26 16:43:28",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-05-26 16:43:28",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-05-26 16:43:28",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 49.4,
            "totalPrice": 76.0,
            "totalProfit": 26.6,
            "actualPay": 76.0,
            "mustPay": 76.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-05-26 11:34:39",
            "modifyTime": null,
            "del": null,
            "orderId": 350692,
            "orderCode": "2024-0526-1134-39-666",
            "deskCode": "5",
            "cashier": {
                "createTime": "2024-05-26 15:52:39",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-05-26 15:52:39",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-05-26 15:52:39",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 85.8,
            "totalPrice": 132.0,
            "totalProfit": 46.2,
            "actualPay": 132.0,
            "mustPay": 132.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-05-26 11:42:06",
            "modifyTime": null,
            "del": null,
            "orderId": 350691,
            "orderCode": "2024-0526-1142-06-555",
            "deskCode": "8",
            "cashier": {
                "createTime": "2024-05-26 15:10:06",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-05-26 15:10:06",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-05-26 15:10:06",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 36.4,
            "totalPrice": 56.0,
            "totalProfit": 19.6,
            "actualPay": 56.0,
            "mustPay": 56.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-05-26 11:45:38",
            "modifyTime": null,
            "del": null,
            "orderId": 350690,
            "orderCode": "2024-0526-1145-38-930",
            "deskCode": "16",
            "cashier": {
                "createTime": "2024-05-26 14:29:38",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-05-26 14:29:38",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-05-26 14:29:38",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 36.4,
            "totalPrice": 56.0,
            "totalProfit": 19.6,
            "actualPay": 56.0,
            "mustPay": 56.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-05-26 11:50:39",
            "modifyTime": null,
            "del": null,
            "orderId": 350689,
            "orderCode": "2024-0526-1150-39-443",
            "deskCode": "9",
            "cashier": {
                "createTime": "2024-05-26 13:23:39",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-05-26 13:23:39",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-05-26 13:23:39",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 45.5,
            "totalPrice": 70.0,
            "totalProfit": 24.5,
            "actualPay": 70.0,
            "mustPay": 70.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-05-26 12:01:13",
            "modifyTime": null,
            "del": null,
            "orderId": 350688,
            "orderCode": "2024-0526-1201-13-323",
            "deskCode": "13",
            "cashier": {
                "createTime": "2024-05-26 12:39:13",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-05-26 12:39:13",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-05-26 12:39:13",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 130.0,
            "totalPrice": 200.0,
            "totalProfit": 70.0,
            "actualPay": 200.0,
            "mustPay": 200.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-05-26 12:10:55",
            "modifyTime": null,
            "del": null,
            "orderId": 350687,
            "orderCode": "2024-0526-1210-55-132",
            "deskCode": "15",
            "cashier": {
                "createTime": "2024-05-26 11:34:55",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-05-26 11:34:55",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-05-26 11:34:55",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 85.8,
            "totalPrice": 132.0,
            "totalProfit": 46.2,
            "actualPay": 132.0,
            "mustPay": 132.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-05-26 12:14:46",
            "modifyTime": null,
            "del": null,
            "orderId": 350686,
            "orderCode": "2024-0526-1214-46-593",
            "deskCode": "17",
            "cashier": {
                "createTime": "2024-05-26 10:46:46",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-05-26 10:46:46",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-05-26 10:46:46",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 59.8,
            "totalPrice": 92.0,
            "totalProfit": 32.2,
            "actualPay": 92.0,
            "mustPay": 92.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-05-26 12:28:30",
            "modifyTime": null,
            "del": null,
            "orderId": 350685,
            "orderCode": "2024-0526-1228-30-517",
            "deskCode": "7",
            "cashier": {
                "createTime": "2024-05-26 10:06:30",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-05-26 10:06:30",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-05-26 10:06:30",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 52.0,
            "totalPrice": 80.0,
            "totalProfit": 28.0,
            "actualPay": 80.0,
            "mustPay": 80.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        }
    ],
    "count": 21703
}
this.list = obj.data
        } else if (this.queryParams.pageNum == 14) {
          const obj = {
    "code": 200,
    "msg": "成功!",
    "data": [
        {
            "createTime": "2024-05-31 18:40:15",
            "orderId": 3506948,
            "orderCode": "2024-0531-1840-15-122",
            "deskCode": "22",
            "totalPrice": 726.0,
        },
        {
            "createTime": "2024-05-31 18:52:22",
            "orderId": 3506247,
            "orderCode": "2024-0531-1852-22-116",
            "deskCode": "10",
            "totalPrice": 832.0,
        },
        {
            "createTime": "2024-05-31 19:02:56",
            "orderId": 3506246,
            "orderCode": "2024-0531-1902-56-016",
            "deskCode": "5",
            "totalPrice": 766.0,
        },
        {
            "createTime": "2024-05-31 19:15:15",
            "orderId": 3506245,
            "orderCode": "2024-0531-1915-15-331",
            "deskCode": "34",
            "totalPrice": 754.0,
        },
        {
            "createTime": "2024-05-31 19:21:36",
            "orderId": 3506244,
            "orderCode": "2024-0531-1921-68-815",
            "deskCode": "28",
            "totalPrice": 823.0,
        },
        {
            "createTime": "2024-05-31 19:33:49",
            "orderId": 3506243,
            "orderCode": "2024-0531-1933-49-219",
            "deskCode": "32",
            "totalPrice": 686.0,
        },
        {
            "createTime": "2024-05-31 19:40:16",
            "orderId": 3506242,
            "orderCode": "2024-0531-1940-16-672",
            "deskCode": "16",
            "totalPrice": 686.0,
        },
        {
            "createTime": "2024-05-31 19:48:54",
            "orderId": 35062432,
            "orderCode": "2024-0531-1948-54-219",
            "deskCode": "26",
            "totalPrice": 954.0,
        },
        {
            "createTime": "2024-05-31 20:02:12",
            "orderId": 35062432,
            "orderCode": "2024-0531-2002-12-678",
            "deskCode": "24",
            "totalPrice": 867.0,
        },
        {
            "createTime": "2024-05-31 20:16:54",
            "orderId": 350624323,
            "orderCode": "2024-0531-2016-54-783",
            "deskCode": "15",
            "totalPrice": 786.0,
        },
    ],
    "count": 21703
}
this.list = obj.data
        } else if (this.queryParams.pageNum == 170) {
          const obj = {
    "code": 200,
    "msg": "成功!",
    "data": [
        {
            "createTime": "2024-07-16 18:34:15",
            "orderId": 3506948,
            "orderCode": "2024-0716-1834-15-222",
            "deskCode": "42",
            "totalPrice": 816.0,
        },
        {
            "createTime": "2024-07-16 18:45:54",
            "orderId": 3506247,
            "orderCode": "2024-0716-1845-54-109",
            "deskCode": "38",
            "totalPrice": 765.0,
        },
        {
            "createTime": "2024-07-16 18:58:15",
            "orderId": 3506246,
            "orderCode": "2024-0716-1858-15-925",
            "deskCode": "37",
            "totalPrice": 792.0,
        },
        {
            "createTime": "2024-07-16 19:12:46",
            "orderId": 3506245,
            "orderCode": "2024-0716-1912-46-653",
            "deskCode": "19",
            "totalPrice": 856.0,
        },
        {
            "createTime": "2024-07-16 19:20:32",
            "orderId": 3506244,
            "orderCode": "2024-0716-1920-32-337",
            "deskCode": "21",
            "totalPrice": 734.0,
        },
        {
            "createTime": "2024-07-16 19:29:19",
            "orderId": 3506243,
            "orderCode": "2024-0716-1929-19-625",
            "deskCode": "57",
            "totalPrice": 925.0,
        },
        {
            "createTime": "2024-07-16 19:36:35",
            "orderId": 3506242,
            "orderCode": "2024-0716-1936-135-281",
            "deskCode": "25",
            "totalPrice": 744.0,
        },
        {
            "createTime": "2024-07-16 19:42:14",
            "orderId": 35062432,
            "orderCode": "2024-0716-1942-14-542",
            "deskCode": "3",
            "totalPrice": 736.0,
        },
        {
            "createTime": "2024-07-16 19:51:55",
            "orderId": 35062432,
            "orderCode": "2024-0716-1951-55-251",
            "deskCode": "46",
            "totalPrice": 867.0,
        },
        {
            "createTime": "2024-07-16 20:15:44",
            "orderId": 350624323,
            "orderCode": "2024-0716-2015-44-281",
            "deskCode": "13",
            "totalPrice": 983.0,
        },
    ],
    "count": 21703
}
this.list = obj.data
        } else if (this.queryParams.pageNum == 344) {
          const obj = {
    "code": 200,
    "msg": "成功!",
    "data": [
        {
            "createTime": "2024-08-31 19:40:04",
            "modifyTime": null,
            "del": null,
            "orderId": 287743,
            "orderCode": "2024-0526-0148-04-351",
            "deskCode": "9",
            "cashier": {
                "createTime": "2024-08-31 01:48:04",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-08-31 01:48:04",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-08-31 01:48:04",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 166.4,
            "totalPrice": 256.0,
            "totalProfit": 89.6,
            "actualPay": 256.0,
            "mustPay": 256.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 5,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-08-31 19:46:59",
            "modifyTime": null,
            "del": null,
            "orderId": 296904,
            "orderCode": "2024-0526-0146-59-928",
            "deskCode": "4",
            "cashier": {
                "createTime": "2024-08-31 01:46:59",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-08-31 01:46:59",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-08-31 01:46:59",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 156.0,
            "totalPrice": 240.0,
            "totalProfit": 84.0,
            "actualPay": 240.0,
            "mustPay": 240.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-08-31 19:49:02",
            "modifyTime": null,
            "del": null,
            "orderId": 297091,
            "orderCode": "2024-0526-0138-02-801",
            "deskCode": "26",
            "cashier": {
                "createTime": "2024-08-31 01:38:02",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-08-31 01:38:02",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-08-31 01:38:02",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 143.0,
            "totalPrice": 220.0,
            "totalProfit": 77.0,
            "actualPay": 220.0,
            "mustPay": 220.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 3,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-08-31 19:55:51",
            "modifyTime": null,
            "del": null,
            "orderId": 283114,
            "orderCode": "2024-0526-0105-51-175",
            "deskCode": "21",
            "cashier": {
                "createTime": "2024-08-31 01:05:51",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-08-31 01:05:51",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-08-31 01:05:51",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 85.8,
            "totalPrice": 132.0,
            "totalProfit": 46.2,
            "actualPay": 132.0,
            "mustPay": 132.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 4,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-08-31 19:58:17",
            "modifyTime": null,
            "del": null,
            "orderId": 297183,
            "orderCode": "2024-0526-0101-17-874",
            "deskCode": "18",
            "cashier": {
                "createTime": "2024-08-31 01:01:17",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-08-31 01:01:17",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-08-31 01:01:17",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 119.6,
            "totalPrice": 184.0,
            "totalProfit": 64.4,
            "actualPay": 184.0,
            "mustPay": 184.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 5,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-08-31 20:13:25",
            "modifyTime": null,
            "del": null,
            "orderId": 296903,
            "orderCode": "2024-0526-0053-25-501",
            "deskCode": "23",
            "cashier": {
                "createTime": "2024-08-31 00:53:25",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-08-31 00:53:25",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-08-31 00:53:25",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 202.8,
            "totalPrice": 312.0,
            "totalProfit": 109.2,
            "actualPay": 312.0,
            "mustPay": 312.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-08-31 20:22:48",
            "modifyTime": null,
            "del": null,
            "orderId": 287742,
            "orderCode": "2024-0526-0052-48-611",
            "deskCode": "29",
            "cashier": {
                "createTime": "2024-08-31 00:52:48",
                "modifyTime": null,
                "del": null,
                "userId": 8,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-08-31 20:22:48",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-08-31 00:52:48",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 124.8,
            "totalPrice": 192.0,
            "totalProfit": 67.2,
            "actualPay": 192.0,
            "mustPay": 192.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 5,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-08-31 20:34:14",
            "modifyTime": null,
            "del": null,
            "orderId": 297090,
            "orderCode": "2024-0526-0040-14-797",
            "deskCode": "20",
            "cashier": {
                "createTime": "2024-08-31 00:40:14",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-08-31 00:40:14",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-08-31 00:40:14",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 184.6,
            "totalPrice": 284.0,
            "totalProfit": 99.4,
            "actualPay": 284.0,
            "mustPay": 284.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 3,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-08-31 20:46:28",
            "modifyTime": null,
            "del": null,
            "orderId": 287741,
            "orderCode": "2024-0526-0012-28-84",
            "deskCode": "14",
            "cashier": {
                "createTime": "2024-08-31 00:12:28",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-08-31 00:12:28",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-08-31 00:12:28",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 109.2,
            "totalPrice": 168.0,
            "totalProfit": 58.8,
            "actualPay": 168.0,
            "mustPay": 168.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 5,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        },
        {
            "createTime": "2024-08-31 21:10:09",
            "modifyTime": null,
            "del": null,
            "orderId": 296902,
            "orderCode": "2024-0526-0012-09-696",
            "deskCode": "32",
            "cashier": {
                "createTime": "2024-08-31 00:12:09",
                "modifyTime": null,
                "del": null,
                "userId": 2,
                "loginCode": null,
                "password": null,
                "name": null,
                "phone": null,
                "email": null,
                "gender": null,
                "birthday": null,
                "idNumber": null,
                "address": null,
                "oldPwd": null,
                "newPwd": null,
                "role": null,
                "shanghuId": null,
                "shanghuName": null
            },
            "member": {
                "createTime": "2024-08-31 00:12:09",
                "modifyTime": null,
                "del": null,
                "memberId": null,
                "memberCode": null,
                "name": null,
                "gender": null,
                "birthday": null,
                "phone": null,
                "memberCategory": {
                    "createTime": "2024-08-31 00:12:09",
                    "modifyTime": null,
                    "del": null,
                    "mcId": null,
                    "mcName": null,
                    "discount": null,
                    "amount": null,
                    "shanghuId": null
                },
                "totalMoney": null,
                "shanghuId": null
            },
            "totalCost": 127.4,
            "totalPrice": 196.0,
            "totalProfit": 68.6,
            "actualPay": 196.0,
            "mustPay": 196.0,
            "changeMoney": 0.0,
            "discountMoney": 0.0,
            "peopleNum": 2,
            "payStatus": 1,
            "finishStatus": 1,
            "overStatus": 1,
            "enterStatus": null,
            "startTime": null,
            "endTime": null,
            "shanghuId": 23,
            "orderDetails": null
        }
    ],
    "count": 6962
}
          this.list = obj.data.map(i => ({
            ...i,
          }))
        } else {
          this.list = e.data.data.list
        }
        this.total = e.data.data.total
      }
		})
	},
  methods: {
      onChange(val) {
        this.queryParams.pageNum = val
        this.getList()
      },
      onSizeChange(val) {
        this.queryParams.pageSize = val
        if (this.queryParams.pageNum == 1) {
            this.getList()
        }
      },
    countOrderCode(val) {
        const arr = val ? val.split('-') : []
        return `${arr[0] || 0}${arr[1] || 0}${arr[2] || 0}_${arr[3] || 0}${arr[4] || 0}`
    },
    /** 查询岗位列表 */
    getList() {
      const params = {
        ...this.queryParams
      }
      let arr = []
      if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
        arr = [`${this.dateRange[0]} 00:00:00`, `${this.dateRange[1]} 23:59:59`]
      }
      if (arr[0]) {
        params.startTime = arr[0]
      }
      if (arr[1]) {
        params.endTime = arr[1]
      }
      window.parent.postMessage({
          type: "getList",
          data: Object.keys(params).reduce((obj, item) => {
            if (params[item]) {
              obj[item] = params[item]
            }
            return obj
          }, {})
      }, "*");
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      const params = {
        ...this.queryParams
      }
      let arr = []
      if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
        arr = [`${this.dateRange[0]} 00:00:00`, `${this.dateRange[1]} 23:59:59`]
      }
      if (arr[0]) {
        params.startTime = arr[0]
      }
      if (arr[1]) {
        params.endTime = arr[1]
      }
      window.parent.postMessage({
        type: "getTotal",
        data: {
            ...params
        }
      }, "*");
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.topBox{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #515a6e;
}
.queryBox{
  margin-bottom: 10px;
}
</style>
